import Cookies from 'js-cookie'

const TokenKey: string = 'token'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token: string) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export const getPublicKey = () => {
  const publicKey =
    'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAmYyUODlTnFqRZEvzU0cjVh0u53ERaUKwL4omekZY/p09srSr+vOdo9AbL9PRv5bD3KZzHMV3NH3aA+MEbzaqnS5rhNlY6SSuUxQ3Ck+KLfQYeZlXxI/1WuqyLPC5ffUCdMtPELjr3SX3jbRzz12KhsTGcbL++LBuabbwjEoLqUfG7KDZLk5GJEVJWrcy+GOFIgI1gl3LsWuGSRAWPt4F9x7xcrqNDDeS7PItd9/l1Qdl0jnxlfqlxZvGArvwSxB7Yv8+E4ByrJQywRCVfPoejmunrSxczEMw9RPgObWn9SxTKrqAxtX3EDc9y7wSD0sRbN6+gzrP/gQUlIpQt78KCQIDAQAB'
  return publicKey
}
