// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
// defineApp
export { defineApp } from './core/defineApp'
export type { RuntimeConfig } from './core/defineApp'
// plugins
export { useAntdConfig, useAntdConfigSetter } from '/Users/sakuya/hysmic/hysmic-stargazing-management/src/.umi-production/plugin-antd';
export { connect, useDispatch, useStore, useSelector, getDvaApp } from '/Users/sakuya/hysmic/hysmic-stargazing-management/src/.umi-production/plugin-dva';
// plugins types.d.ts
export * from '/Users/sakuya/hysmic/hysmic-stargazing-management/src/.umi-production/plugin-antd/types.d';
export * from '/Users/sakuya/hysmic/hysmic-stargazing-management/src/.umi-production/plugin-dva/types.d';
// @umijs/renderer-*
export { createBrowserHistory, createHashHistory, createMemoryHistory, Helmet, HelmetProvider, createSearchParams, generatePath, matchPath, matchRoutes, Navigate, NavLink, Outlet, resolvePath, useLocation, useMatch, useNavigate, useOutlet, useOutletContext, useParams, useResolvedPath, useRoutes, useSearchParams, useAppData, useClientLoaderData, useLoaderData, useRouteProps, useSelectedRoutes, useServerLoaderData, renderClient, __getRoot, Link, useRouteData, __useFetcher, withRouter } from '/Users/sakuya/hysmic/hysmic-stargazing-management/node_modules/@umijs/renderer-react';
export type { History, ClientLoader } from '/Users/sakuya/hysmic/hysmic-stargazing-management/node_modules/@umijs/renderer-react'
// umi/client/client/plugin
export { ApplyPluginsType, PluginManager } from '/Users/sakuya/hysmic/hysmic-stargazing-management/node_modules/umi/client/client/plugin.js';
export { history, createHistory } from './core/history';
export { terminal } from './core/terminal';
// react ssr
export const useServerInsertedHTML: Function = () => {};
