// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React from 'react';

export async function getRoutes() {
  const routes = {"1":{"path":"/","redirect":"/home","parentId":"@@/global-layout","id":"1"},"2":{"path":"*","redirect":"/404","parentId":"@@/global-layout","id":"2"},"3":{"path":"/login","parentId":"@@/global-layout","id":"3"},"4":{"path":"","parentId":"5","id":"4","originPath":"/home"},"5":{"path":"/home","isWrapper":true,"parentId":"@@/global-layout","id":"5"},"6":{"title":"首页","icon":"icon-app-store","path":"/home/index","parentId":"4","id":"6"},"7":{"title":"活动","path":"/home/activities","icon":"icon-calendar-check","parentId":"4","id":"7"},"8":{"path":"/home/activities","redirect":"/home/activities/list","parentId":"7","id":"8"},"9":{"title":"活动列表","path":"/home/activities/list","parentId":"7","id":"9"},"10":{"path":"/404","parentId":"@@/global-layout","id":"10"},"@@/global-layout":{"id":"@@/global-layout","path":"/","isLayout":true}} as const;
  return {
    routes,
    routeComponents: {
'1': React.lazy(() => import('./EmptyRoute')),
'2': React.lazy(() => import('./EmptyRoute')),
'3': React.lazy(() => import(/* webpackChunkName: "p__Login__Login" */'@/pages/Login/Login.tsx')),
'4': React.lazy(() => import(/* webpackChunkName: "layouts__HomeMenu__index" */'@/layouts/HomeMenu/index.tsx')),
'5': React.lazy(() => import(/* webpackChunkName: "wrappers__auth" */'@/wrappers/auth.tsx')),
'6': React.lazy(() => import(/* webpackChunkName: "p__Homepage__Homepage" */'@/pages/Homepage/Homepage.tsx')),
'7': React.lazy(() => import('./EmptyRoute')),
'8': React.lazy(() => import('./EmptyRoute')),
'9': React.lazy(() => import(/* webpackChunkName: "p__ActivitiesList__ActivitiesList" */'@/pages/ActivitiesList/ActivitiesList.tsx')),
'10': React.lazy(() => import(/* webpackChunkName: "p__NotFound__NotFound" */'@/pages/NotFound/NotFound.tsx')),
'@@/global-layout': React.lazy(() => import(/* webpackChunkName: "layouts__index" */'/Users/sakuya/hysmic/hysmic-stargazing-management/src/layouts/index.tsx')),
},
  };
}
