import axios, { AxiosHeaders, AxiosRequestConfig, AxiosResponse } from 'axios'
import { message } from 'antd'
import { getToken, removeToken } from './auth'

interface HttpRequest {
  publicPath: string
  queue: any
  xsrfCookieEnabled: boolean
}

class HttpRequest {
  constructor(publicPath: string) {
    this.publicPath = publicPath
    this.queue = {}
    this.xsrfCookieEnabled = false
  }

  getInsideConfig() {
    let headers = {} as AxiosHeaders
    const token = getToken()
    if (!!token) {
      headers['token'] = token
    } else {
      headers = {} as AxiosHeaders
    }
    headers['Content-Type'] = 'application/json'
    const config = {
      baseURL: this.publicPath,
      headers
    }
    return config
  }

  destroy(url: string) {
    delete this.queue[url]
    if (!Object.keys(this.queue).length) {
      // message.close()
    }
  }

  interceptors(instance: any, url: string) {
    // 请求拦截
    instance.interceptors.request.use(
      (config: AxiosRequestConfig<any>) => {
        // 添加全局的loading...
        //   if (!Object.keys(this.queue).length) {
        //     // message.loading() // 不建议开启，因为界面不友好
        //   }
        if (!!config.data && typeof config.data === 'object') {
          if (config.data.type === 'registerUser') {
            // config.data = qsStringify(config.data)
          }
        }
        this.queue[url] = true
        return config
      },
      (error: Promise<unknown>) => {
        return Promise.reject(error)
      }
    )

    // 响应拦截
    instance.interceptors.response.use(
      (res: AxiosResponse<any, any>) => {
        this.destroy(url)
        const { data: resData, status } = res
        const { resultCode } = resData
        if (resultCode === 1000) {
          if (status === 200) {
            if (!!resData.data) {
              return resData.data
            } else {
              if (!!resData.message) {
                if (
                  typeof resData.message === 'string' &&
                  resData.message.includes('成功')
                ) {
                  message.success(resData.message, 2)
                } else {
                  message.warning(resData.message, 2)
                }
                return resData.message
              }
            }
          } else {
            message.error(resData.message, 2)
          }
        } else {
          message.error(resData.message, 2)
        }
      },
      (error: any) => {
        this.destroy(url)
        let errorInfo = error.request
        console.warn(errorInfo)

        if (!!errorInfo) {
          if (errorInfo.status === 400 || errorInfo.status === 500) {
            message.error(`${errorInfo.status}服务器错误`, 2)
          }
          if (errorInfo.status === 401) {
            removeToken()
            message.error(`请登录后重试`, 2)
          }
          if (String(errorInfo.status).startsWith('4') && errorInfo.status > 401) {
            console.warn(errorInfo)

            message.error(errorInfo.data.message, 2)
          }
        } else {
          const {
            request: { statusText, status },
            config
          } = JSON.parse(JSON.stringify(error))
          errorInfo = {
            statusText,
            status,
            request: { responseURL: config.url }
          }
        }
        return Promise.reject(error)
      }
    )
  }

  request(options: any) {
    const instance = axios.create({ withCredentials: true })
    const insideConfing = this.getInsideConfig()
    if (!!insideConfing) {
      options = Object.assign(this.getInsideConfig(), options)
    }
    this.interceptors(instance, options.url)
    return instance(options)
  }
}

export default HttpRequest
